import { css, SerializedStyles } from "@emotion/react";
import Image, { FluidObject } from "gatsby-image";
import * as React from "react";

export const NonStretchedImage = React.memo<
  {
    css?: SerializedStyles | SerializedStyles[];
    fluid: FluidObject & { presentationWidth: number };
  } & React.ComponentProps<typeof Image>
>(props => {
  return (
    <Image
      css={[
        css`
          width: 100%;
          max-width: ${props.fluid.presentationWidth}px;
          margin: 0 auto;
        `,
        props.css
      ]}
      {...props}
    />
  );
});
