import styled from "@emotion/styled";
import { graphql, Link, useStaticQuery } from "gatsby";
import { FluidObject } from "gatsby-image";
import { padding } from "polished";
import * as React from "react";
import { theme } from "../../theme";
import { NonStretchedImage } from "./NonStretchImage";

export const Header = React.memo(() => {
  const data =
    useStaticQuery<{
      flowersStart: ImageSharpFluid;
      flowersEnd: ImageSharpFluid;
    }>(HEADERS_QUERY);

  return (
    <StyledHero1>
      <NonStretchedImage fluid={data.flowersStart.childImageSharp.fluid} />
      <h1>Jack and Nicola</h1>

      <small>1st May 2021</small>
    </StyledHero1>
  );
});

interface ImageSharpFluid {
  childImageSharp: { fluid: FluidObject & { presentationWidth: number } };
}

const StyledHero1 = styled("div")`
  color: ${theme.palette.light.text};
  font-family: ${theme.font.body};

  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
  min-height: 300px;
  ${padding("80px", 0, "60px", 0)};

  line-height: 3em;

  & > h1 {
    font-size: 3rem;
    font-weight: bold;

    padding-top 4rem;
    // padding-bottom:1rem;
    margin-block-end: 0em;
  }

  & > small {
    font-size: 2.3rem;
  }
`;

const HEADERS_QUERY = graphql`
  query HeadersQuery {
    flowersStart: file(relativePath: { eq: "flowers/Artboard 2.png" }) {
      childImageSharp {
        fluid(maxWidth: 700) {
          ...GatsbyImageSharpFluid
          presentationWidth
        }
      }
    }
  }
`;
