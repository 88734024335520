import { css, SerializedStyles } from "@emotion/react";
import { graphql, useStaticQuery } from "gatsby";
import { FluidObject } from "gatsby-image";
import * as React from "react";
import { NonStretchedImage } from "./NonStretchImage";

export const Footer = React.memo<{ css?: SerializedStyles }>(props => {
  const data = useStaticQuery<{
    flowersEnd: ImageSharpFluid;
  }>(FOOTERS_QUERY);

  return (
    <NonStretchedImage
      css={[
        css`
          margin-top: 1rem;
          margin-bottom: 5rem;
        `,
        props.css
      ]}
      fluid={data.flowersEnd.childImageSharp.fluid}
    />
  );
});

interface ImageSharpFluid {
  childImageSharp: { fluid: FluidObject & { presentationWidth: number } };
}

const FOOTERS_QUERY = graphql`
  query FootersQuery {
    flowersEnd: file(relativePath: { eq: "flowers/Artboard 1.png" }) {
      childImageSharp {
        fluid(maxWidth: 600) {
          ...GatsbyImageSharpFluid
          presentationWidth
        }
      }
    }
  }
`;
